import * as React from 'react';
import { ParcoursWrapper, ParcourTextWrapper, ParcourDivider } from './parcours.styles';
import Title from '../../components/title/title';
import Text from '../../components/text/text';
import { themes } from '../../styles/variables';
import Button from '../../components/button/button';
import BackgroundImage from '../../assets/backgrounds/background-parcours.svg';
import ParcourImage from '../../assets/images/parcours.png';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import IconButton from '../../components/icon-button/iconButton';
import ArrowLeftIcon from '../../assets/images/arrow-left.svg';
import ArrowRightIcon from '../../assets/images/arrow-right.svg';
import Impression1Image from '../../assets/images/impression-1.jpg';
import Impression2Image from '../../assets/images/impression-2.jpg';
import Impression3Image from '../../assets/images/impression-3.jpg';
import Impression4Image from '../../assets/images/impression-4.jpg';
import Impression5Image from '../../assets/images/impression-5.jpg';
import Impression6Image from '../../assets/images/impression-6.jpg';
import Impression7Image from '../../assets/images/impression-7.jpg';
import { openNewTab } from '../../helpers/navigation';
import { useTranslation } from 'react-i18next';


export type ParcoursProps = {
    classes?: string
};

const Parcours = (props: ParcoursProps): JSX.Element => {

    const { t } = useTranslation();

    const navigateToAnker = () => {
        window.location.href = '#contact';
    }

    const openBlogArticle = () => {
        var url = t('parcours_button_link');
        openNewTab(url);
    }

    return (
        <ParcoursWrapper className={`${props.classes || ''} -mt-desktop tablet:-mt-bottom`} id={'parcours'}>
            <img src={BackgroundImage} className={'-mb-2 min-w-full'} alt={'children playing in park'} />
            <ParcourTextWrapper className={'p-mobile desktop:p-desktop py-bottom pb-0 pt-2 bg-bluemedium'}>
                <div className={'flex flex-row-reverse'}>
                    <img src={ParcourImage} className={'max-w-half tablet:max-w-image tablet:mr-overlapTablet -mt-overlapDesktop'} alt={'woman jumping through parcours'} />
                </div>
                <div className={'tablet:-mt-mobile m-auto max-w-screen'}>
                    <Title text={'parcours_title'} theme={themes.light} className={'tablet:max-w-half'} />
                    <div className={'grid tablet:grid-cols-2 gap-3 tablet:gap-mobile'}>
                        <Text text={'parcours_text_left'} theme={themes.light} />
                        <Text text={'parcours_text_right'} theme={themes.light} />
                    </div>
                    <Button text={'parcours_button_1'} classes={'bg-white'} onClick={navigateToAnker} />
                    <Button text={'parcours_button_2'} classes={'bg-white'} onClick={openBlogArticle} />
                </div>
                <div className={'slider max-w-tablet m-auto tablet:pb-desktop mt-desktop'}>
                    <AwesomeSlider className={''} organicArrows={false} bullets={true}  
                        buttonContentLeft={<IconButton alt={'LinkedIn'} icon={ArrowLeftIcon} classes={'orange -rotate-180'} />}
                        buttonContentRight={<IconButton alt={'LinkedIn'} icon={ArrowRightIcon} classes={'orange'}/>}
                    >
                        <div data-src={Impression1Image} />
                        <div data-src={Impression2Image} />
                        <div data-src={Impression3Image} />
                        <div data-src={Impression4Image} />
                        <div data-src={Impression5Image} />
                        <div data-src={Impression6Image} />
                        <div data-src={Impression7Image} />
                    </AwesomeSlider>
                </div>
            </ParcourTextWrapper>
            <ParcourDivider />
        </ParcoursWrapper>
    );
};

export default Parcours;

