import styled from "styled-components";
import { colors } from "../../styles/variables";

export const FaqItemWrapper = styled.div`
    border: 4px solid ${colors.grey};
    box-shadow: 5px 5px 0px rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    cursor: pointer;

    .faq-item {
        display: none;
        max-width: 90%;

        &.open {
            display: block;
        } 
    }
`;