import * as React from 'react';
import { openNewTab } from '../../helpers/navigation';
import { IconButtonWrapper } from './iconButton.styles';

export type IconButtonProps = {
    alt: string,
    icon: string,
    classes?: string,
    url?: string
};

const IconButton = (props: IconButtonProps): JSX.Element => {

    const handleClick = () => {
        if (props.url) {
            openNewTab(props.url);
        }
    };

    return (
        <IconButtonWrapper className={`flex ${props.classes || ''}`} onClick={handleClick}>
            <img src={props.icon} alt={props.alt} className={'p-2'} />
        </IconButtonWrapper>
    );
};

export default IconButton;

