import React from 'react';
import Stage from '../modules/stage/stage';
import Partners from '../modules/partners/partners';
import Team from '../modules/team/team';
//import Quote from '../modules/quote/quote';
import Parcours from '../modules/parcours/parcours';
import Footer from '../modules/footer/footer';
import Faq from '../modules/faq/faq';
import Header from '../modules/header/header';
import Contact from '../modules/contact/contact';
import About from '../modules/about/about';
import { scrollToElement } from '../helpers/navigation';

type IndexPageProps = {

};

const IndexPage = (props: IndexPageProps): JSX.Element => {

    let faqItems = [
        {
            title: 'faq_4_title',
            text: 'faq_4_text'
        },
        {
            title: 'faq_1_title',
            text: 'faq_1_text'
        },
        {
            title: 'faq_2_title',
            text: 'faq_2_text'
        },
        {
            title: 'faq_3_title',
            text: 'faq_3_text'
        }
    ];

    return (
        <div className={'page'}>
            <Header />
            <Stage title={'stage_title'} text={'stage_text'} button={'stage_button'} onButtonClick={() => scrollToElement('parcours')} />
            <About />
            <Parcours />
            <Team />
            <Partners />
            <Contact />
            <Faq items={faqItems} />
            <Footer />
        </div>
    );
};

export default IndexPage;