import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from '../../styles/variables';
import { TitleWrapper } from './title.styles';

export type TitleProps = {
    text: string,
    theme?: string,
    className?: string
};

const Title = (props: TitleProps): JSX.Element => {

    const { t } = useTranslation();

    return (
        <TitleWrapper className={`title text-${props.theme || themes.dark} font-title text-md tablet:text-xl mb-3 desktop:mb-5 ${props.className || ''}`} dangerouslySetInnerHTML={{ __html: t(props.text) }} />
    );
};

export default Title;

