import styled from "styled-components";
import { colors } from "../../styles/variables";

export const VideoWrapper = styled.div`

    rotate: 2deg;

    img {
        position: absolute;
        bottom: 128px;
        right: -87px;
        width: 160px;
        z-index: 2;
    }

    .video-container { 
        position: relative; 
        padding-bottom: 56.25%; 
        padding-top: 30px; 
        height: 0; 
        max-width: 900px;
        margin: auto;
    }

    .video-container iframe, .video-container object, .video-container embed { 
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%;

        @media(min-width: 1000px) {
            max-height: 500px;
        }
    }

    iframe {
        box-shadow: 15px 15px 0px ${colors.bluemedium};

    }
`;