import styled from "styled-components";
import BackgroundImage from '../../assets/backgrounds/background-contact.svg';
import BackgroundImageMobile from '../../assets/backgrounds/background-contact-mobile.svg';

import { breakpoints } from "../../styles/variables";

export const ContactWrapper = styled.div`
`;

export const ContactContentWrapper = styled.div`
background-size: contain;
background-image: url(${BackgroundImageMobile}), linear-gradient(180deg, rgba(255,255,255,1) 38%, rgba(45,118,181,1) 38%);
background-repeat: repeat-x;
background-position-y: top;

@media(${breakpoints.tablet}) {
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
}

.textblock.grid {
    display: flex;
}
`;