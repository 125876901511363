import * as React from 'react';
import { FooterWrapper } from './footer.styles';
import ImageFooter from '../../assets/images/footer.svg';
import { useTranslation } from 'react-i18next';
import IconButton from '../../components/icon-button/iconButton';
//import InstagramIcon from '../../assets/images/instagram.svg'
import MediumIcon from '../../assets/images/medium.svg'
import LinkedInIcon from '../../assets/images/linkedin.svg'

export type FooterProps = {
    classes?: string
};

const Footer = (props: FooterProps): JSX.Element => {

    const { t } = useTranslation();

    // Social Media Links
    const MediumURL = 'http://saschabardua.medium.com';
    const LinkedInURL = 'https://www.linkedin.com/company/mosaic-lernparkour';
    //const InstagramURL = '';

    return (
        <FooterWrapper className={`${props.classes || ''} bg-bluedark px-mobile pt-mobile desktop:px-desktop desktop:pt-mobile text-center pb-0`}>
            <div className={'grid tablet:grid-cols-2 tablet:text-left'}>
                <div>
                    <p className={'font-title text-md text-white'}>{t('footer_title')}</p>
                    <p className={'font-title text-base text-white text-opacity-50'}>{t('footer_subtitle')}</p>
                    <div className={'flex justify-center tablet:justify-start my-4'}>
                        <IconButton alt={'medium'} icon={MediumIcon} url={MediumURL} />
                        <IconButton alt={'linkedin'} icon={LinkedInIcon} classes={'mx-2'} url={LinkedInURL} />
                        {/*<IconButton alt={'instagram'} icon={InstagramIcon} url={InstagramURL} />*/}
                    </div>
                    <a href={'/impressum'} className={'font-text text-base text-white underline'}>{t('footer_impressum')}</a>
                </div>
                <img src={ImageFooter} alt={'woman blowing kisses with hand'} />
            </div>
        </FooterWrapper>
    );
};

export default Footer;

