
export function scrollToElement(anker: string) {
    if (anker) {
        window.location.href = `#${anker}`;
    }
}

export function openNewTab(url: string) {
    if (url) {
        window.open(url, '_blank');
    }
}

export function mailTo(subject: string, email: string = 'mosaic-lernparkour@outlook.com') {
    if (email) {
        window.location.href = `mailto:${email}${subject ? `?subject=${subject}` : ''}`
    }
}