import styled from "styled-components";
import BackgroundImage from '../../assets/backgrounds/background-stage.svg';
import { breakpoints, breakpointValues } from "../../styles/variables";


export const StageWrapper = styled.div`
    background-size: cover;
    background-image: url(${BackgroundImage});
    background-repeat: repeat-x;
    background-position-y: bottom;
    box-sizing: border-box;

    @media(${breakpoints.tablet}) {
        max-height: 800px;
    }

    .title {
        @media(max-width: ${breakpointValues.tablet}) {
            font-size: 38px;
            line-height: 38px;
        }
    }
`;