import * as React from 'react';
import IconButton from '../icon-button/iconButton';
import Image from '../image/image';
import Text from '../text/text';
import Title from '../title/title';
import { TeamMemberWrapper } from './team-member.styles';
import LinkedInIcon from '../../assets/images/linkedin.svg'
import XingIcon from '../../assets/images/xing.svg'

export type TeamMemberProps = {
    className?: string,
    src: string,
    text?: string,
    name: string,
    role?: string,
    linkedIn: string,
    xing?: string
};

const TeamMember = (props: TeamMemberProps): JSX.Element => {

    return (
        <TeamMemberWrapper className={'team-member flex-auto justify-items-center text-center mb-6'}>
            <div className={'inline-block'}>
                <Image src={props.src} alt={props.name} className={'mb-5'} />
                <IconButton alt={'LinkedIn'} icon={LinkedInIcon} classes={'linkedin'} url={props.linkedIn} />
                <IconButton alt={'Xing'} icon={XingIcon} classes={`xing ${props.xing ? '' : 'invisible'}`} url={props.xing} />
            </div>
            <Title text={props.name} className={'mt-desktop desktop:pt-bottom desktop:-mt-5'} />
            {props.role && <Text text={props.role} className={'-mt-3'} />}
            {props.text && <Text text={props.text} className={'mt-3'} />}
        </TeamMemberWrapper>
    );
};

export default TeamMember;

