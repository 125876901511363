import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from '../../styles/variables';
import { SeparatorLine, TextListItemWrapper, TextListTitle, TextListWrapper } from './text-list.styles';

export type TextListProps = {
    title?: string,
    items: Array<string>,
    theme?: string,
    className?: string,
    onClick?: () => void
};

const TextList = (props: TextListProps): JSX.Element => {

    const { t } = useTranslation();

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <div className='mb-desktop'>
        {props.title && <b><TextListTitle className='font-text text-base desktop:text-md my-2 desktop:my-4 px-2 '>{t(props.title)}</TextListTitle></b>}
        <TextListWrapper className={`text-${props.theme || themes.dark} font-text text-base desktop:text-md my-1 desktop:my-3 ${props.className || ''}`} onClick={handleClick}>
            {props.items.map(item => {
                return <TextListItemWrapper><li >{t(item)}</li><SeparatorLine/></TextListItemWrapper>;
            })}
        </TextListWrapper>
        </div>
    );
};

export default TextList;

