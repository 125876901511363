import * as React from 'react';
import { HeaderWrapper } from './header.styles';
import Logo from '../../assets/logos/Mosaic.svg';
import Button from '../../components/button/button';
import i18n from '../../lang/i18n';
import { initReactI18next } from 'react-i18next';
import Text from '../../components/text/text';
import { scrollToElement } from '../../helpers/navigation';

export type HeaderProps = {
    classes?: string
};

const Header = (props: HeaderProps): JSX.Element => {

    const [lng, setLng] = React.useState(i18n.language);

    const changeLanguage = () => {
        let lang = lng === 'en' ? 'de' : 'en';
        setLng(lang);
        i18n.use(initReactI18next)
            .init({ lng: lang });
    };

    return (
        <HeaderWrapper className={`${props.classes || ''} flex justify-between bg-red p-mobile pt-2 desktop:p-desktop desktop:pt-3 -mb-1`}>
            <a href={'/'}>
                <img src={Logo} alt={'Mosaic Lernparkour Logo'} className={'w-sm desktop:w-md'} />
            </a>
            <div className={'flex items-center'}>
                <Text text={`header_switchto_${lng === 'en' ? 'de' : 'en'}`} onClick={changeLanguage} className={'tablet:mr-3 text-md p-0 inline lang-switch'} />
                <Button text={'header_button'} classes={'hidden tablet:inline bg-white'} onClick={() => scrollToElement('contact')} />
            </div>
        </HeaderWrapper>
    );
};

export default Header;

