import React from 'react';
import './build/styles.final.css'; // or the correct path to your final CSS file
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import IndexPage from './pages/index';
import ImpressumPage from './pages/impressum';
import ConferencePage from './pages/oeb';
//import CookieBanner from './modules/cookie-banner/CookieBanner';

function App(): JSX.Element {

  return (
    <div className={'app max-width'}>
      <Router>
        <Routes>
          <Route path={'/impressum'} element={<ImpressumPage />} />
          <Route path={'/oeb'} element={<ConferencePage />} />
          <Route path={'/'} element={<IndexPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
