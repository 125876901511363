import * as React from 'react';
import { themes } from '../../styles/variables';
import { StageWrapper } from './stage.styles';
import StageImg from '../../assets/images/Landing Graphic.svg'
import Textblock from '../textblock/textblock';

export type StageProps = {
    title: string,
    text: string,
    button: string,
    image?: string,
    onButtonClick?: () => void
};

const Stage = (props: StageProps): JSX.Element => {

    return (
        <StageWrapper className={'p-mobile desktop:p-desktop'}>
            <Textblock title={props.title} text={props.text} button={props.button} theme={themes.light} image={StageImg} imageAlt={'Junge springt über Hindernissparcour'} classes={'max-w-screen m-auto -mb-desktop'} onClick={() => props.onButtonClick ? props.onButtonClick() : null} />
        </StageWrapper>
    );
};

export default Stage;

