import * as React from 'react';
import { AboutWrapper } from './about.styles';
import Textblock from '../textblock/textblock';
import AboutImage from '../../assets/images/Complexity.svg';
import { useTranslation } from 'react-i18next';
import { openNewTab } from '../../helpers/navigation';
import Video from '../../components/video/video';
import Quote from '../quote/quote';


export type AboutProps = {
    classes?: string
};

const About = (props: AboutProps): JSX.Element => {

    const { t } = useTranslation();

    const openBlogArticle = () => {
        var url = t('about_blog_link');
        openNewTab(url);
    }

    return (
        <AboutWrapper className={`${props.classes || ''} p-mobile desktop:p-desktop pt-bottom m-auto max-w-screen`}>
            <Video videoUrl={'https://www.youtube.com/embed/HyJkc2zU84w'} className={'video'}/>
            <Textblock title={'about_context_title'} text={'about_context_text'} image={AboutImage} imageAlt={'Mädchen denkt an digitale Medien'} right={true} />
            <Textblock title={'about_problem_title'} text={'about_problem_text'} button={'about_problem_button'} onClick={openBlogArticle} />
            <Quote text={'about_quote_text'} author={'about_quote_author'} />
        </AboutWrapper>
    );
};

export default About;

