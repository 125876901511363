import styled from "styled-components";


export const TextblockWrapper = styled.div`

    &.image-max img {
        max-width: 450px;
    }

    &.right {
        direction: rtl;

        h1, p, button {
            direction: ltr;
        }
    }
`;