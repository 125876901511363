import * as React from 'react';
import { TeamWrapper } from './team.styles';
import ImageSash from '../../assets/images/Sascha.jpg';
import TeamMember from '../../components/team-member/team-member';
import Text from '../../components/text/text';


export type TeamProps = {
    classes?: string,
    title?: string
};

const Team = (props: TeamProps): JSX.Element => {

    return (
        <TeamWrapper className={`${props.classes || ''} p-mobile py-bottom desktop:px-desktop -mt-1`}>
            <div className={'grid tablet:grid-cols-2 justify-items-center max-w-screen m-auto'}>
                <TeamMember src={ImageSash} name={'Sascha </br>Bardua'} role={'team_sascha_role'} xing={'https://www.xing.com/profile/Sascha_Bardua'} linkedIn={'https://www.linkedin.com/in/saschabardua/'} />
                <Text text={'team_text'} className='px-4' />
            </div>
        </TeamWrapper>
    );
};

export default Team;

