import React from 'react';
import Title from '../components/title/title';
import Header from '../modules/header/header';
import Obfuscate from 'react-obfuscate';
import ImpressumImage from '../assets/images/impressum.jpg';
import Footer from '../modules/footer/footer';

type ImpressumPageProps = {

};

const ImpressumPage = (props: ImpressumPageProps): JSX.Element => {

    return (
        <div className={'page'}>
            <Header />
            <div className={'p-mobile desktop:p-desktop m-auto max-w-screen'}>
                <img src={ImpressumImage} alt={'Impressum'} className={'w-auto max-w-image mb-mobile'} />

                <Title text={'Impressum'} />

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Sascha Bardua<br />
Waldenserstra&szlig;e 7<br />
10551 Berlin</p>

                <h2 className={'mt-5'}>Kontakt</h2>
                <p>Telefon: <Obfuscate tel={'017686348583'} /><br />
E-Mail: <Obfuscate email={'mosaic-lernparkour@outlook.com'} /></p>

                <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
            </div>
            <Footer />
        </div>
    );
};

export default ImpressumPage;