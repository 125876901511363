import styled from "styled-components";

export const ImageWrapper = styled.div`

    overflow: hidden;
    position: relative;
    filter: drop-shadow(10px 14px 0px #2D76B5);
    transition: filter 0.3s ease-in-out, border-width 0.2s ease-in-out;

    &:hover {
        filter: drop-shadow(14px 20px 0px #2D76B5);
        border-width: 6px;
    }

     div {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        transition: transform ease 0.3s;

        &:hover {
            transform: scale(1.1);
        }
     }
`;