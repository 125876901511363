import * as React from 'react';
import { ImageWrapper } from './image.styles';

export type ImageProps = {
    shadowColor?: string,
    className?: string,
    alt?: string,
    src: string,
    size?: string
};

const Image = (props: ImageProps): JSX.Element => {

    return (
        <ImageWrapper className={`image bg-bluemedium border-bluemedium border-4 flex justify-center rounded-full ${props.size || 'w-sm desktop:w-md h-sm desktop:h-md'}`}>
            <div style={{ backgroundImage: `url(${props.src})` }} className={`absolute ${props.className || ''}`} />
        </ImageWrapper>
    );
};

export default Image;

