import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

export const TeamMemberWrapper = styled.div`

   .linkedin {
      margin-top: -33%;
      margin-left: 76%;

      @media(${breakpoints.desktop}) {
      margin-left: 85%;
      }
   }

   .xing {
      margin-top: -46%;
      margin-left: 90%;

      @media(${breakpoints.desktop}) {
         margin-top: -34%;
         margin-left: 93%;
      }
   }
`;