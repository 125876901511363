import React from 'react';
import { useTranslation } from 'react-i18next';
import { openNewTab } from '../helpers/navigation';
import Faq from '../modules/faq/faq';
//import Quote from '../modules/quote/quote';
import Footer from '../modules/footer/footer';
import Header from '../modules/header/header';
import Stage from '../modules/stage/stage';
import Team from '../modules/team/team';
import Textblock from '../modules/textblock/textblock';
import BlogImage from '../assets/images/search-question.jpg';
import Video from '../components/video/video';

type ConferencePageProps = {

};

const ConferencePage = (props: ConferencePageProps): JSX.Element => {

    const { t } = useTranslation();

    let faqItems = [
        {
            title: 'faq_4_title',
            text: 'faq_4_text'
        },
        {
            title: 'faq_1_title',
            text: 'faq_1_text'
        },
        {
            title: 'faq_2_title',
            text: 'faq_2_text'
        },
        {
            title: 'faq_3_title',
            text: 'faq_3_text'
        }
    ];

    const openBlogArticle = () => {
        var url = t('about_blog_link');
        openNewTab(url);
    };

    const downloadSlides = () => {
        let url = 'https://we.tl/t-lgHk41HP4H';
        window.open(url, '_blank');
    };

    return (
        <div className={'page'}>
            <Header />
            <Stage title={'conference_stage_title'} text={'conference_stage_text'} button={'conference_stage_button'} onButtonClick={downloadSlides} />
            <div className={'p-mobile desktop:p-desktop'}>
                <Textblock title={'conference_textblock_1_title'} text={'conference_textblock_1_text'} onClick={openBlogArticle} classes={'mt-3 image-max'} button={'conference_textblock_1_button'} image={BlogImage} />
            </div>
            <Team title={'conference_contact'} />
            {/* Youtube Video Lernparkour */}
            <Video title={'conference_video_title'} videoUrl={'https://www.youtube.com/embed/HyJkc2zU84w'} />
            <Faq items={faqItems} />
            <Footer />
        </div>
    );
};

export default ConferencePage;