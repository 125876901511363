import * as React from 'react';
import Button from '../../components/button/button';
import Text from '../../components/text/text';
import Title from '../../components/title/title';
import { TextblockWrapper } from './textblock.styles';
import { useTranslation } from 'react-i18next';
import { themes } from '../../styles/variables';


export type TextblockProps = {
    title?: string,
    text?: string,
    button?: string,
    right?: boolean,
    classes?: string,
    image?: string,
    imageAlt?: string,
    centered?: boolean,
    theme?: string,
    onClick?: () => void
};

const Textblock = (props: TextblockProps): JSX.Element => {

    const { t } = useTranslation();

    return (
        <TextblockWrapper className={`textblock ${props.right ? 'right' : ''} ${props.centered ? '' : 'grid tablet:grid-cols-2'} mb-mobile tablet:mb-desktop ${props.classes || ''}`}>
            <div className={`text-block-left ${props.centered ? 'flex-auto text-center justify-items-center tablet:max-w-half tablet:m-auto' : ''}`}>
                {props.title && <Title text={t(props.title)} theme={props.theme} />}
                {props.text && <Text text={t(props.text)} theme={props.theme} />}
                {props.button && <Button text={t(props.button)} classes={`${props.theme === themes.light ? 'bg-white' : ''}`} onClick={props.onClick} />}
            </div>
            {props.image && <div className={'text-block-right'}>
                 <img src={props.image} alt={props.imageAlt} className={'max-w-image desktop:max-w-stage-image m-auto w-auto'} />
            </div>}
        </TextblockWrapper>
    );
};

export default Textblock;

