import * as React from 'react';
import { ContactWrapper, ContactContentWrapper } from './contact.styles';
import Textblock from '../textblock/textblock';
import ContactImage from '../../assets/images/contact.png'
import { themes } from '../../styles/variables';
import { mailTo } from '../../helpers/navigation';
import { useTranslation } from 'react-i18next';


export type ContactProps = {
    classes?: string
};

const Contact = (props: ContactProps): JSX.Element => {

    const { t } = useTranslation();

    return (
        <ContactWrapper className={`${props.classes || ''} -mt-bottom`} id={'contact'}>
            <div className={'-mb-bottom desktop:-mb-bottom-2 flex'}>
                <img src={ContactImage} className={'w-md desktop:w-lg m-auto'} alt={'two people talking to each other'} />
            </div>
            <ContactContentWrapper className={'grid tablet:grid-cols-2 p-mobile pb-bottom desktop:p-desktop'}>
                <Textblock title={'contact_left_title'} text={'contact_left_text'} button={'contact_left_button'} classes={'tablet:mr-4 mt-desktop'} theme={themes.light} onClick={() => mailTo(t('contact_subject_project'))} />
                <Textblock title={'contact_right_title'} text={'contact_right_text'} button={'contact_right_button'} classes={'tablet:ml-4 mt-desktop'} theme={themes.light} onClick={() => mailTo(t('contact_subject_team'))} />
            </ContactContentWrapper>
        </ContactWrapper>
    );
};

export default Contact;

