import styled from "styled-components";
import BackgroundImageLightBlue from '../../assets/images/icon-button.svg';
import BackgroundImageOrange from '../../assets/images/icon-button-orange.svg';


export const IconButtonWrapper = styled.a`
    
    cursor: pointer;
    height: 60px;
    width: 60px;

    &:before {
        background-size: contain;
        background-image: url(${BackgroundImageLightBlue});
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        width: 60px;
        height: 60px;
        transition: transform 0.3s ease-in-out;
        display: flex;

    }

    &.orange:before {
        background-image: url(${BackgroundImageOrange});
    }

    &:hover {
        &::before {
            transform: rotate(45deg) scale(1.1);
        }
    }

    img {
        height: 60px;
        width: 60px;
        z-index: 1;
        position: absolute;
        transition: transform 0.3s ease-in-out;


        :hover {
            transform: rotate(-10deg) scale(1.1);
        }
    }
`;