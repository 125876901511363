import * as React from 'react';
import { PartnersWrapper } from './partners.styles';
import Textblock from '../textblock/textblock';
import { mailTo } from '../../helpers/navigation';
import { useTranslation } from 'react-i18next';
import TextList from '../../components/text-list/text-list';

export type PartnersProps = {
    classes?: string
};

const Partners = (props: PartnersProps): JSX.Element => {

    const parkourPartners = [
        "Coding Grundlagen - HausDrei Hamburg",
        "Internetsicherheit - Festival für Freunde",
        "Internetsuchen - Generation Athletics",
        "Digitale Nachhaltigkeit - Klimaliste Berlin",
        "Agiles Arbeiten - Taikonauten"
    ];

    const eventPartners = [
        "Code Week 2023, Hamburg",
        "Tech Camp Konferenz, Hamburg",
        "Aktionsfonds, Berlin",
        "Hacker School, Hamburg",
        "edusiia, Berlin",
        "Coconat, Brandenburg"
    ];

    const { t } = useTranslation();

    return (
        <PartnersWrapper className={`${props.classes || ''} p-mobile desktop:p-desktop`}>
            <Textblock title={'partners_title'} text={'partners_text'} button={'partners_button'} centered={true} onClick={() => mailTo(t('contact_subject_partner'))} />
            <div className='grid tablet:grid-cols-2 text-center'>
                <TextList title={"partners_parkours"} items={parkourPartners}/>
                <TextList title={"partners_friends"} items={eventPartners}/>
            </div>

        </PartnersWrapper>
    );
};

export default Partners;

