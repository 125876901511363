import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import DE from './de.json';
import EN from './en.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: (navigator.language || navigator.userLanguage).substr(0, 2),
    fallbackLng: 'de',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

i18n.addResourceBundle('de', 'translation', DE);
i18n.addResourceBundle('en', 'translation', EN);

export default i18n;
