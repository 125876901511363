import * as React from 'react';
import IconButton from '../icon-button/iconButton';
import { FaqItemWrapper } from './faqItem.styles';
import PlusIcon from '../../assets/images/plus.svg';
import Text from '../text/text';

export type FaqItemProps = {
    classes?: string,
    item: {
        title: string,
        text: string
    }
};

const FaqItem = (props: FaqItemProps): JSX.Element => {

    const [isOpen, setIsOpen] = React.useState(false);

    const toggleFaq = () => {
        setIsOpen(!isOpen);
    };

    return (
        <FaqItemWrapper className={'bg-white p-1 px-4 mb-3'} onClick
            ={toggleFaq}>
            <div className={'mb-0 flex flex-initial items-center'}>
                <Text text={props.item.title} className={'flex-grow font-bold'} />
                <IconButton alt={'Öffne Frage'} icon={PlusIcon} />
            </div>
            <Text text={props.item.text} className={`faq-item ${isOpen ? 'open' : ''}`} />
        </FaqItemWrapper>
    );
};

export default FaqItem;

