import styled from "styled-components";
import BackgroundImage from '../../assets/backgrounds/background-impressions.svg';
import { colors } from "../../styles/variables";

export const ParcoursWrapper = styled.div`
`;

export const ParcourTextWrapper = styled.div`
    
    .slider {

        rotate: -2deg;

        .awssld__wrapper {
            border-radius: 20px;
            border: black 4px solid;
            box-shadow: 12px -12px 0px ${colors.orange};
        }

        .awssld__bullets button {
            background: ${colors.orange};
            margin: 5px 10px;
        }
    }
`;

export const ParcourDivider = styled.div`

    background-size: cover;
    background-image: url(${BackgroundImage});
    background-repeat: repeat-x;
    background-position-y: bottom;
    min-height: 200px;

    
`;