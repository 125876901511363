import * as React from 'react';
import FaqItem from '../../components/faq-item/faqItem';
import { themes } from '../../styles/variables';
import Textblock from '../textblock/textblock';
import { FaqWrapper } from './faq.styles';

export type FaqProps = {
    classes?: string,
    items: Array<{
        title: string,
        text: string
    }>
};

const Faq = (props: FaqProps): JSX.Element => {

    return (
        <FaqWrapper className={`${props.classes || ''} p-2 desktop:p-desktop -mt-desktop tablet:-mt-bottom`}>
            <Textblock title={'faq_title'} theme={themes.light} centered={true} classes={'mt-desktop'} />
            {props.items.map((item) => {
                return <FaqItem item={item} />
            })}
        </FaqWrapper>
    );
};

export default Faq;

