import styled from "styled-components";
import BackgroundImage from '../../assets/backgrounds/background-team.svg';

export const TeamWrapper = styled.div`
    background-size: cover;
    background-image: url(${BackgroundImage});
    background-repeat: repeat-x;
    background-position-y: bottom;

    .team-member {
        max-width: 350px;

        img {
            max-width: 300px;
        }
    }
`;