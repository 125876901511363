import * as React from 'react';
import Textblock from '../../modules/textblock/textblock';
import { VideoWrapper } from './video.styles';
import RainbowImage from '../../assets/images/rocket.png';

export type VideoProps = {
    title?: string,
    className?: string,
    videoUrl: string
};

const Video = (props: VideoProps): JSX.Element => {

    return (
        <VideoWrapper className={`video -mt-4 mb-desktop tablet:mt-0 tablet:mb-8 p-2 ${props.className || ''}`}>
            {props.title && <Textblock title={props.title} centered={true} />}
            {props.videoUrl && <div className={'video-container'}>
                <img src={RainbowImage} className={'hidden desktop:block'} alt="Rakete über Videoplayer"/>
                <iframe src={props.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className={'m-auto border-4 border-black'} style={{ borderRadius: 40 }}></iframe>
            </div>}
        </VideoWrapper>
    );
};

export default Video;

