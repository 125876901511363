import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper } from './button.styles';

export type ButtonProps = {
    text: string,
    onClick?: () => void,
    classes?: string
};

const Button = (props: ButtonProps): JSX.Element => {

    const { t } = useTranslation();

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <ButtonWrapper className={`font-text tablet:text-base px-3 py-1 my-1 mr-2 ${props.classes || ''}`} onClick={handleClick}>
            {t(props.text)}
        </ButtonWrapper>
    );
};

export default Button;

