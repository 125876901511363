export const colors = {
    grey: '#303030',
    red: '#EE9181',
    orange: '#F2B382',
    bluelight: '#70C0F9',
    bluemedium: '#2D76B5',
    bluedark: '#1A4D7C',
    white: '#fff'
};

export const breakpointValues = {
    mobile: '0px',
    tablet: '720px',
    desktop: '1280px'
};

export const breakpoints = {
    mobile: `min-width: ${breakpointValues.mobile}`,
    tablet: `min-width: ${breakpointValues.tablet}`,
    desktop: `min-width: ${breakpointValues.desktop}`,
};

export const themes = {
    dark: 'grey',
    light: 'white'
};