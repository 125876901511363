import * as React from 'react';
import Text from '../../components/text/text';
import { QuoteWrapper } from './quote.styles';
import { useTranslation } from 'react-i18next';


export type QuoteProps = {
    author: string,
    text: string
};

const Quote = (props: QuoteProps): JSX.Element => {

    const { t } = useTranslation();

    return (
        <QuoteWrapper className={'flex-auto p-mobile desktop:p-desktop text-center tablet:max-w-quarter m-auto'}>
            <Text text={`"${t(props.text)}"`} className={'text-md tablet:text-lg desktop:text-lg'} />
            <Text text={`- ${t(props.author)} -`} className={'text-orange'} />
        </QuoteWrapper>
    );
};

export default Quote;

