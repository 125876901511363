import styled from "styled-components";
import { colors } from "../../styles/variables";

export const ButtonWrapper = styled.button`

    border: 4px solid ${colors.grey};
    box-shadow: 5px 5px 0px rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    cursor: pointer;

    &:hover {
        /*margin: 10px 10px 0 0;*/
        transform: scale(1.05) translateY(5px) translateX(5px);
        box-shadow: 15px 12px 0px rgba(255, 255, 255, 0.5);
    }
`;