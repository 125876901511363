import styled from "styled-components";
import { colors } from "../../styles/variables";

export const TextListWrapper = styled.ul`

    li {
        cursor: pointer;
        display: inline-block;
        padding: 4px 10px;
        transition: background-color 0.5s;

        &:hover {
            background: ${colors.bluelight};
        }
    }
`;

export const TextListItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SeparatorLine = styled.hr`
    border: dashed ${colors.bluelight} 2px;
    width: 40px;
    display: inline-block;
    margin: 10px 0px;
`;

export const TextListTitle = styled.span`
    border-bottom: solid 6px ${colors.bluelight};
`;
