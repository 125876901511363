import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from '../../styles/variables';
import { TextWrapper } from './text.styles';

export type TextProps = {
    text: string,
    theme?: string,
    className?: string,
    onClick?: () => void
};

const Text = (props: TextProps): JSX.Element => {

    const { t } = useTranslation();

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <TextWrapper className={`text-${props.theme || themes.dark} font-text text-base desktop:text-md my-1 desktop:my-3 ${props.className || ''}`} dangerouslySetInnerHTML={{ __html: t(props.text) }} onClick={handleClick} />
    );
};

export default Text;

